var calcSmm = $(".js-calc-smm");


function calcSmmfunc() {
    let Total = 20000,
        count = calcSmm.find($(".js-calc-smm-item[data-name='social']:checked")).length
    
    if(count > 1) {
        Total = Total + 5000*(count - 1);
    }

    if ($(".js-calc-smm-item[data-name='design']").prop("checked") == true) {
        Total = Total + 2000;
    }

    if ($(".js-calc-smm-item[data-name='marketing']").prop("checked") == true) {
        Total = Total + 5000;
    }


    Total=Total.toString();

    $(".js-calc-smm-total").text(Total.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, "$1" + ' '))
}


$(".js-calc-smm-item").change(function () {
    calcSmmfunc($(this));
});

